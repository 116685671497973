.btn-submit{
    display: flex;
    justify-content: flex-end;
}

.profile-main-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
}