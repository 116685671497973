.ant-layout{
    height:100vh
}
.backColor {
  background-image: url('./Assets/grayBack.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;


}
/* .ant-layout-sider{
    height: none !important;
} */