.plaground-header-description{
    background: rgb(255, 255, 255);
    margin: 12px 14px -10px ;
    border-radius: 10px;
    padding-left: 10px;
    width:70%;
    display: flex;
    align-items: center;
}
.footer_sider_bar{
    margin-bottom: 8px;
    color: #a6adb4;
    font-size: 15px;
    padding: 8px 16px;
    cursor: pointer;
    transition: color 0.4s;
    text-decoration: none;
}
.footer_sider_bar:hover {
  color: white;
}
.main-collapsed-siderbar-footer{
    position: absolute ;
    bottom: 46px ; 
    left: 12px ;
}
.main-siderbar-footer{
    position: absolute;
    bottom: 46px; 
    left: 25px;
}
.profile-balance{
    margin: 50px;
    background-color: #f0f2f5;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
}
.comp-logo{
    display: flex;
    justify-content: center;
    margin: 30px;
}
.balance-main{
    display: flex;
    justify-content: center;
    align-items: center;
}
.balance-fontsize{
    font-size: 20px;
    font-weight: bold;
    color: #896ACB;
}
.expand-collapse-button{
    font-size: 16px;
    width: 64px;
    height: 64px;
}