.model-heading{
    font-size: 20px;
    font-weight: 400;
}
.model-dropdown{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.system-prompt-title,.language-title{
    font-size: 15px;
    display: flex;
    justify-content: center;
    font-weight: bold;
    margin-bottom: 10px;
}
.parameters-title,.voice-title{
    font-size: 15px;
    display: flex;
    justify-content: center;
    font-weight: bold;
}
.main-parameters,.main-voice-container{
    margin-top: 10px;
    display: flex;
    justify-content: center;
}
.parameters-container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.system-prompt-dropdown,.language-dropdown{
    display: flex;
    justify-content: center;
}
.parameter-text-edit{
    background-color: rgb(230, 247, 255);
    padding: 2px;
    border-radius: 5px;
}
.parameter-edit-field{
    width: 60px;
    font-size: 12px;
}
.modification-container{
    border: 1px solid rgb(235, 235, 235);
    height: 55vh;
    overflow: auto;
    margin-top: 2vh;
    border-radius: 8px;
}
.modification-title{
    font-size: 20px;
}
.edit-system-prompt{
    display: flex;
    justify-content: space-between;
}
.main-add-system-prompt-fields ,.edit-system-prompt-fields{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: 20vh;
}
.add-system-prompt-button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
    border: none;
    background: none;
}
.system-prompt-edit{
    position: absolute;
    left: 100%;
    margin-left: 5px;
    cursor: pointer;
    opacity: 0;
}