body, html { 
  font-family: Roboto, sans-serif;  
  float: left; 
  width: 100%; 
  margin: 0px; 
  padding: 0px; 
}



.form-md { 
  width: 100%; 
  text-align: center; 
  margin: 32px auto 32px auto; 
}
.form-md .form-group {
  margin-left: auto;
  margin-right: auto;
  max-width: 320px;
}
.form-group {
  margin-bottom: 31px;
  position: relative;
}
input .form_name {
  position: relative;
  display: block;
  width: 100%;
  border: 1px solid rgba(0,0,0,.37);
  border-radius: 4px;
  background-color: transparent;
  margin: 0px auto;
  padding: 6px 4px 4px 14px;
  height: 60px;
  outline: none !important;
  font-size: 16px;
  color: rgba(0,0,0,0.85);
  transition: all .2s ease-in-out;
}

.form-group label {
  position: absolute;
  top: 17px; 
  left: 12px;
  text-align: left;
  display: inline-block;
  padding: 0 4px;
  height: 14px;
  line-height: 14px;
  font-size: 12px;
  font-weight: 400;
  background: #fff;
  color: rgba(0,0,0,0.5);
  margin: 0px auto;
  cursor: text;
  transition: all .15s ease-in-out;
}
input:hover, input:focus { border: 1px solid #000; }

input:valid + label, input:focus + label,input[readonly] + label { 
  top: -6px;
  color: #000;
}

.gl-form-asterisk {
    background-color: inherit;
    color: #e32b2b;
    padding: 0;
    padding-left: 3px;
   
}
.gl-form-asterisk:after {
    content: "*";
} 

.pass-wrapper {
    position: relative;
}

.eye-icon {
    position: absolute;
    right: 15px; /* Adjust the right value to position the icon as needed */
    top: 25px;
    transform: translateY(-50%);
    cursor: pointer;
}
.form-control{
    font-size: 0.9rem;
    height: 50px;
}


