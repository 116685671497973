.container {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.text {
  color: black;
  font-size: 35px;
  font-weight: 500;
}

.underline {
  width: 55px;
  height: 6px;
  background: black;
  border-radius: 9px;
}

.inputs {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.main-navigation-login{
  margin:15px;
}

.input input {
  display: flex;
  flex-direction: column;
  height: 35px;
  background: transparent;
  border: none;
  outline: none;
  color: #797979;
  font-size: 14px;
}
.forget-password {
  /* padding-left: 62px; */
  margin-top: 10px;
  color: #797979;
  font-size: 14px;
}

.forget-password span {
  color: #4c00b4;
  cursor: pointer;
}
.submit-container {
  display: flex;
  justify-content: center;
  margin: 60px auto;
  border: none;
  outline: none;
}
.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 38px;
  color: #ffffff;
  background: black;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border:none;
  border-radius: 20px;
}

.gray {
  background: #eaeaea;
  color: #676767;
}
.log-error-message {
  font-size: 10px;
  color: red;
  margin-top: -6%;
  margin-left: 5px;
}
.login-question {
  color: #676767;
  font-size: 14px;
}
.login-statement {
  color: #4c00b4;
  cursor: pointer;
  font-size: 20px;
}

.main-login-container{
  display: flex;
  align-items: center;
  width: 100%;
}
.login-side-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: rgb(15, 15, 15);
  color: #ffffff;
  font-size: 34px;
}
.login-side-logo{
  width: 300px;
  height: 50px;
  margin: 50px;
}
.Login-form{
  display: flex;
  align-items: center;
  width: 70%;
  height: 100vh;
  justify-content: center;
}

.centreMinisky {

  display: flex !important;
  justify-content: center !important; 
  align-items: center !important;
  height: 100vh !important;


}