.main-speech-text-conatiner{
    height: 68vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.audio-file-drag{
    height: 20vh;
    margin-bottom: 40px;
}

.main-text-speech-container{
    border: 1px solid rgb(235, 235, 235);
    height: 68vh;
}
.speech-convert-button{
    display: flex;
    justify-content: flex-end;
    margin: 10px;
}
.play-audio-player{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}