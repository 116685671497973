.message {
  transition: transform 0.5s ease-in-out;
}
.main-chat-container{
  border-radius: 8px;
  border: 1px solid rgb(235, 235, 235);
}
.chat-container{
  background: #F5F5F5;
  height: 500px;
  overflow-y: auto;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 0;
}
.chat-response{
  padding: 10px;
  margin: 5px;
  border-radius: 8px;
  overflow: hidden;
}
#chat-request {
  background: #292827;
  color: white;
  padding: 8px;
  border-radius: 20px;
  font-family: monospace;
  word-wrap: break-word;
  display: inline-block;
  text-align: left;
  overflow: hidden;
  margin: 0;
}
.chatbox-container{
  display: flex;
  height: 5vh;
}
.chat-box-reset{
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-code-block{
  position: relative;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
}
.main-code-block-copy{
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.chat-send-tooltip{
  color: #1890ff;
  font-size: 17px;
  cursor: pointer;
}

.animated-message {
  animation: fadeIn 1s ease-in-out;
  background: #dfeded;
  border-radius: 20px;
  padding: 8px;
  font-family: monospace;
  font-size: 15px;
  word-wrap: break-word;
  display: inline-block;
  white-space: pre-wrap;
  overflow: hidden;
  margin: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

